<template>
    <div class="client-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="add" type="primary" size="small" icon="el-icon-plus" round>添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button @click="dialogTableVisible=true" 
                    type="warning" size="small" icon="el-icon-upload2" round>导入</el-button>
                </el-form-item>
                <el-form-item v-if="exportRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item label="客户">
                        <el-autocomplete
                        class="inline-input"
                        v-model="form.client"
                        :fetch-suggestions="searchClient"
                        placeholder="搜索客户"
                        :trigger-on-focus="false"
                        @select="selectClient"
                        ></el-autocomplete>
                </el-form-item>
                <el-form-item label="客户类型">
                    <el-select v-model="form.client_type" placeholder="客户类型">
                    <el-option v-for="item in parameters.type" :key="item.pid"
                    :label="item.desc" :value="item.desc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所在区域">
                    <el-select v-model="form.region" placeholder="所在区域">
                    <el-option v-for="item in parameters.regions" :key="item.pid"
                    :label="item.desc" :value="item.desc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-client -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="clientList"
             @selection-change="handleSelectionChange"
            style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="shortname"
                    width="150"
                    label="客户简称">
                </el-table-column>
                <el-table-column
                    prop="num"
                    width="100"
                    label="客户编号">
                </el-table-column>
                <el-table-column
                    prop="region"
                    width="120"
                    label="区域">
                </el-table-column>
                <el-table-column
                    prop="grade"
                    width="80"
                    label="等级">
                </el-table-column>
                <el-table-column
                    prop="client_type"
                    width="120"
                    label="类型">
                </el-table-column>
                <el-table-column
                    prop="contact"
                    width="120"
                    label="联系人">
                </el-table-column>
                <el-table-column
                    prop="landline"
                    width="150"
                    label="固定电话">
                    <template slot-scope="scope">
                        {{scope.row.landline|jsDecode(user.firmid)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    width="150"
                    label="移动电话">
                    <template slot-scope="scope">
                        {{scope.row.mobile|jsDecode(user.firmid)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    width="250"
                    label="联系地址">
                </el-table-column>
                <el-table-column
                    prop="status"
                    width="100"
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button size="mini" plain :type="scope.row.status==0?'primary':'info'">
                                {{scope.row.status==0?'启用':'未启用'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1]" :key="item" @click.native="changeSatus(scope.row.cid,item)">
                                {{item==0?'启用':'未启用'}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    fixed="right"
                    v-if="modifyRule"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.cid)"></i>
                        <i class="edit el-icon-edit" @click="handleUpadte(scope.row)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- list-client-end -->
        <!-- page分页 -->
        <div class="pageNav" v-if="clientList.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- page-end -->
        <!-- addCLient添加客户 -->
        <el-drawer
          :title="editText"
          :visible.sync="drawer"
          size="50%"
          :direction="direction">
            <AddClient @addSuc="addSuc" :currentClient="currentClient" @editTy="editTy"/>
        </el-drawer>
        <!-- 编辑类型/等级/区域 -->
        <el-drawer
          :title="editParmsTitle"
          :visible.sync="isEditType"
          size="30%"
          :direction="direction">
            <AddType :type="editType" :list="currentParameters" @refresh="refreshParams"/>
        </el-drawer>
        <!-- 弹出框 -->
        <el-dialog title="导入客户" :visible.sync="dialogTableVisible">
            <ImportClient @close="dialogTableVisible=false"/>
        </el-dialog>
    </div>
</template>
<script>
import {exportExcel} from '@/utils/excel.js'
import {checkRules} from '@/utils/util.js'
import AddClient from '@/components/AddClient'
import AddType from '@/components/AddType'
import ImportClient from '@/components/ImportClient'
export default {
    components:{
        AddClient,
        AddType,
        ImportClient
    },
    data(){
        return {
            dialogTableVisible:false,//显示弹出导入客户
            addRule:checkRules(6),//添加客户权限
            exportRule:checkRules(8),//导出客户权限
            modifyRule:checkRules(7),//修改客户权限
            editText:'添加客户',
            editParmsTitle:'',
            editType:0,//要修改的params类型,
            currentParameters:[],//临时要修改的params数据,类型/区域/等级
            currentClient:{},//要更改的客户信息
            direction:'rtl',
            drawer:false,
            isEditType:false,//编辑类型区域
            user:{},
            totalPage:0,//总页码
            clientList:[],
            loading:false,
            page:1,
            pageSize:6,
            pageArr:[6,10,15,20],
            form:{},
            clients:[{
                value:'选择'
            }],//选择提示客户列表
            parameters:{
                type:[],
                regions:[]
            },//客户类型
            downloadList:[] //导出列表
        }
    },
    created(){
        console.log(this.$options.filters['jsDecode'])
        //获取客户区域/类型参数
        this.getParameters()
        //获取客户列表
        this.getClientList()
        //用户信息
        this.user=JSON.parse(sessionStorage.getItem('user'))
    },
    methods:{
        //导出客户表
        download(){
            if(this.loading){
                return
            }
            let params = this.form,that= this
            params.method = 'getClientList'
            params.page = 1
            params.pageSize = 5000
            this.loading = true
            //console.log(this.form)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.loading = false
                let columnTitle=['客户编号','全称','简称','区域','等级','类型','联系人','移动电话','座机','邮箱','地址','状态']
            let columnData = []
            console.log(res.data.list)
            res.data.list.forEach(it=>{
                    let mobile = that.$options.filters['jsDecode'](it.mobile,that.user.firmid)
                    ,landline = that.$options.filters['jsDecode'](it.landline,that.user.firmid)
                    columnData.push([it.num,it.name,it.shortname,it.region,it.grade,it.client_type,it.contact,mobile,landline,it.email,it.address])
            })
            exportExcel(columnTitle, columnData, "客户数据导出明细表");
            }).catch(err=>{console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },
        //更新类型,区域,等级数据
        refreshParams(e){
            //console.log(e.type,e.list)
            let that = this
            switch(parseInt(e.type)){
                        //客户等级
                        case 0:
                            that.gradeList=e.list
                            break;
                        //客户类型
                        case 1:
                            that.typeList=e.list
                            break;
                        //客户区域
                        case 2:
                            that.areaList=e.list
                            break;
                    }
        },
        //编辑类型:1=>类型,2=>区域,0=>等级
        editTy(e){
            console.log(e)
            this.isEditType = true 
            this.editType = e.type 
            this.currentParameters = e.data 
            this.editParmsTitle = e.title
        },
        //添加客户成功
        addSuc(){
            this.closeDrawerd()
            this.search(this.page)
        },
        //选择数据
        handleSelectionChange(e){
            console.log(e)
        },
        //选择每页显示数量
        choisePageSize(e){
            this.pageSize = e 
            this.getClientList()
        },
        //选择页码
        currentChange(e){
           // console.log(e)
           this.page = e
           this.getClientList()
        },
        //更改客户
        handleUpadte(data){
            //console.log(data)
            this.drawer = true 
            this.editText = "修改客户"
            //json操作防止数据被改变
            this.currentClient = JSON.parse(JSON.stringify(data)) 
        },
        //删除客户
        handleDelete(cid){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:'delClient',
                        cid:cid
                    }
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        this.$message({
                            message:res.msg||this.CONST.DEL_SUC,
                            type:'success'
                        })
                        //刷新
                        this.search(this.page)
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }).catch(()=>{
                    this.$message({
                        message: this.CONST.DEL_CANCEL,
                        type: 'warning'
                    });
                })
        },
        //弹出搜索客户
        searchClient(queryString, cb){
            let params = {
                        method:'getClientList',
                        key:this.form.keys,
                        page:1,
                        pageSize:5
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        console.log(res)
                        if(res.data.list){
                            let clients = res.data.list;
                            console.log(clients)
                            let results = queryString ? 
                            clients.filter(this.createFilter(queryString)) : clients;
                            // 调用 callback 返回建议列表的数据
                            console.log(results)
                            cb(results);
                        }
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        createFilter(queryString) {
            return (clients) => {
                clients.value = clients.shortname
            return (clients.shortname.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        //选择客户
        selectClient(e){
            console.log(e)
        },
        //搜索
        search(page){
            if(!page){
                this.page = 1 
            }
            this.getClientList()
        },
        //关闭弹窗 
        closeDrawerd(){
            this.drawer = false
        },
        //添加客户
        add(){
            this.currentClient={}
            this.drawer = true
            this.editText = "添加客户"
        },
        getParameters(){
            let params = {
                method:"getParameters"
            }
            let that = this
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                res.data.forEach(function(item){
                    if(item.pa_type==1){
                        that.parameters.type.push(item)
                    }
                    else if(item.pa_type==2){
                        that.parameters.regions.push(item)
                    }
                })
            }).catch(err=>{
                //err
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },
        getClientList(){
            let params = this.form
            params.method = 'getClientList'
            params.page = this.page 
            params.pageSize = this.pageSize
            // let params = {
            //     method:'getClientList',
            //     page:this.page,
            //     pageSize:this.pageSize
            // }
            this.loading = true
            //console.log(this.form)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                this.loading = false
                this.clientList = res.data.list 
                this.totalPage = res.data.totalPage
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.client-box{
    padding:5px 0;

    .header-bar{
        margin:10px 0;
        display: flex;

        .input{
            width:230px;
            margin-left: 10px;
        }
        .time-box{
            width:350px;
            margin-left: 10px;
        }
        .margin{
            margin-left: 10px;
        }
    }
    // 分页数
    .pageNav{
        padding: 10px 0;
        display: flex;
        .el-dropdown{
            line-height: 28px;
        }
    }
}
</style>