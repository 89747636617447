<template>
    <div class="add-task-box">
        <el-form v-loading="loading" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="客户全称" class="demo-form-inline" required prop="name">
                <el-input v-model="form.name" 
                placeholder="客户全称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item label="客户简称" required prop="shortname">
                <el-input v-model="form.shortname" @input="inputShortname"
                placeholder="客户简称"></el-input>
            </el-form-item>
            <el-form-item label="客户编号" required prop="num">
                <el-input v-model="form.num" placeholder="客户编号">
                    <el-button size="mini" @click="randomNum" slot="append" type="primary" plain icon="el-icon-thumb">随机编号</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="类型等级">
                 <el-select v-model="form.client_type" placeholder="客户类型">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini" @click="editTy(1)" icon="el-icon-edit" type="primary" plain >类型</el-button>
                <i style="opacity:0"> - </i>
                 <el-select v-model="form.grade" placeholder="客户等级">
                    <el-option
                    v-for="item in gradeList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini"  @click="editTy(0)" icon="el-icon-edit" type="primary" plain >等级</el-button>
            </el-form-item>
            <el-form-item label="联系地址" required prop="address">
                <el-input v-model="form.address" @change="addressChange"
                placeholder="市-区-街道-编号"></el-input>
            </el-form-item>
            <el-form-item label="客户区域" required prop="region">
                <el-select v-model="form.region" placeholder="客户区域">
                    <el-option
                    v-for="item in areaList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini" @click="editTy(2)" type="primary" plain icon="el-icon-edit">区域</el-button>
            </el-form-item>
            <el-form-item label="联 系 人" required prop="contact">
                <el-input v-model="form.contact" placeholder="联系人"></el-input>
            </el-form-item>
            <el-form-item label="移动电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder="移动电话"></el-input>
            </el-form-item>
            <el-form-item label="固定电话" prop="landline">
                <el-input v-model="form.landline" placeholder="固定电话"></el-input>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="email">
                <el-input v-model="form.email" placeholder="联系邮箱"></el-input>
            </el-form-item>
            <el-form-item label="备注信息">
                <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{btnText}}</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
//import {formatTime} from '@/utils/util.js'
export default {
    props:{
        currentClient: { //要更改的客户数据
            type:Object
        }
    },
    watch:{
        currentClient:{
            immediate:true,
            async handler(client) {
                if(client.cid){
                    this.user=JSON.parse(sessionStorage.getItem('user'))
                    this.btnText = "确认修改"
                    //代表client!={}不为空，则是更改内容
                    const filter = this.$root.$options.filters 
                    try {
                        console.log(client.landline,this.user.firmid)
                        client.landline = filter.jsDecode(client.landline,this.user.firmid)
                        client.mobile = filter.jsDecode(client.mobile,this.user.firmid)
                    } catch (error) {
                        console.log(error)
                    }
                    //console.log(filter.jsDecode(client.landline,this.user.firmid))
                    this.form = client 
                    this.update = true
                }else{
                    //代表只添加客户
                    this.btnText = "添加客户"
                    this.update = false 
                    this.form = {
                            num:new Date().getTime()+'_'+Math.ceil(Math.random()*100),
                            landline:'',
                            mobile:'',
                            email:'',
                            remark:'',
                            client_type:'',
                            grade:'',
                            region:''
                        }
                }
            }
        }
    },
    data(){
        var checkMobile = (rule, value, callback) => {
            if(value==''){
              return  callback()
            }
            if (!/^\d+$/.test(value)||value.length!=11) {
                callback(new Error('请输入正确号码'));
            } else {
                callback();
            }
        };
        var checkLandline = (rule,value,callback)=>{
            if(value==''){
                return callback()
            }
            if(!/^(\d{3,4}-)?\d{7,8}$/.test(value)){
                callback(new Error('请输入正确固话！'))
            }else{
                callback()
            }
        }
        return {
            btnText:"立即创建",
            user:{},
            update:false,//是否是更改客户
            loading:false,
            form:{
                num:new Date().getTime()+'_'+Math.ceil(Math.random()*100),
                landline:'',
                mobile:'',
                email:'',
                remark:'',
                client_type:'',
                grade:'',
                region:''
            },
            options:[],
            typeList:[],//客户类型
            areaList:[],//客户区域
            gradeList:[],//客户等级
            rules:{
                name: [
                    { required: true, message: '请输入客户名称', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                shortname: [
                    { required: true, message: '请输入简称！', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入客户地址！', trigger: 'blur' }
                ],
                contact: [
                    { required: true, message: '请输入联系人！', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请输入客户区域！', trigger: 'blur' }
                ],
                mobile: [
                    { validator:checkMobile, trigger: 'blur' },
                ],
                landline: [
                    { validator:checkLandline, trigger: 'blur' },
                ],
                num: [
                    { required: true, message: '请输入编号', trigger: 'blur' },
                    { min: 2, max: 25, message: '长度在 1 到 25 个字符', trigger: 'blur' }
                ],
                email: [
                    { required: false, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ]
            }
        }
    },
    created(){
        this.user=JSON.parse(sessionStorage.getItem('user'))
        this.getParameters()
    },
    methods:{
        //编辑paramters,类型1，等级0，区域2
        editTy(type){
            let data,title
            switch(type){
                case 0:
                    data = this.gradeList
                    title = '客户等级'
                    break;
                case 1:
                    data = this.typeList
                    title = '客户类型'
                    break;
                case 2:
                    data = this.areaList
                    title = '客户区域'
                    break;
            }
            console.log(type,title)
            this.$emit('editTy',{type,data,title})
        },
        //随机编号
        randomNum(){
            this.form.num = new Date().getTime()+'_'+Math.ceil(Math.random()*100)
        },
        //输入地址
        addressChange(e){
            //如果输入地址存在市和区，则将区域提取自动设置
            let districtIndex=e.toString().indexOf("区")
            ,cityIndex = e.toString().indexOf("市")
            //console.log(districtIndex,cityIndex)
           if(districtIndex!=-1&&cityIndex!=-1){
               try {
                   //console.log(e.slice(cityIndex+1,districtIndex))
                   this.form.area = e.slice(cityIndex+1,districtIndex)+"区"
                   this.$forceUpdate()
               } catch (error) {
                   console.log(error)
               }
           } 
        },
        //输入简称
        inputShortname(e){
            console.log(e)
            this.form.shortname = e
            this.$forceUpdate()
        },
        //重置
         resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        //客户名称发生改变时
        nameChange(e){
            console.log(e)
            if(!this.form.shortname){
                //console.log(this.form.shortname)
                this.form.shortname = e
                this.$forceUpdate()
            }
        },
        //保存
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    //console.log('提交了___')
                    let params = this.form 
                    params.method = this.update?"updateClient":"addClient"
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                        this.loading = false 
                        console.log(msg)
                        this.$message({
                            message: msg,
                            type: 'success'
                        })
                        //返回数据
                        this.$emit("addSuc")
                    })
                    .catch(err=>{
                        this.$message({
                            message: err.msg||err,
                            type: 'warning'
                        })
                        this.loading = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //获取客户类型/等级/区域
        getParameters(){
            let params = {
                method:"getParameters"
            },that = this
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                console.log(data)
                this.loading = false
                data.forEach(item => {
                    switch(parseInt(item.pa_type)){
                        //客户等级
                        case 0:
                            that.gradeList.push({
                                id:item.pid,
                                value:item.desc,
                            })
                            break;
                        //客户类型
                        case 1:
                            that.typeList.push({
                                id:item.pid,
                                value:item.desc,
                            })
                            break;
                        //客户区域
                        case 2:
                            that.areaList.push({
                                id:item.pid,
                                value:item.desc,
                            })
                            break;
                    }
                });
            }).catch(err=>{
               // console.log(err)
                this.$message({
                    message: err.msg||err,
                    type: 'warning'
                })
                this.loading = false
            })
        },
    }
}
</script>
<style lang="less">
.add-task-box{
    display: flex;
}
</style>